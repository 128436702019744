.gallery {
    position: relative;
    padding: 20vh 0 0 0;

    @include media-breakpoint-down(sm) {
        padding: 50px 0 20px;
        text-align: center;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(200px) 0 pxToRem(200px);
    }

    &:before {
        @include absoluteTR(18%, 0);
        @include block(64%, auto);
        bottom: 13%;
        content: '';
        z-index: -1;
        @include background-dots($teal);
    }

    &__about {
        padding-right: pxToRem(290px);

        @include media-breakpoint-down(md) {
            padding-right: pxToRem(100px);
        }

        @include media-breakpoint-down(sm) {
            padding-right: 0;
            max-width: 400px;
            margin: 0 auto;
        }

        .h6, h6 {
            margin: pxToRem(34px) 0 pxToRem(34px);

            @include media-breakpoint-down(sm) {
                margin: 20px 0;
            }
        }
    }

    &__btn {
        margin-top: pxToRem(34px);

        @include media-breakpoint-down(sm) {
            margin: 1rem 0 2rem;
        }
    }

    &__params {

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }
    }
}

.gallery-swiper {
    width: 100%;
    height: 75.1%;
    margin-top: 6.7%;
    box-shadow: pxToRem(-20px) 0 pxToRem(10px) pxToRem(-10px) rgba($gray-600, .5);
    max-height: 700px;

    .swiper-slide {
        height: 100%;
        background: $gray-600;

        img {
            @include block(100%, 100%);
            object-fit: cover;
        }
    }
}