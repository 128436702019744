.we-are {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 17.2vh;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
        font-size: 15px;
        text-align: center;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(200px) 0 pxToRem(200px);
    }

    &:before {
        @include absoluteTL(27.6%, 0);
        @include block(68.8%, auto);
        bottom: 5.1%;
        content: '';
        z-index: 1;
        @include background-dots($orange);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__boxes {
        width: 90.7%;
    }

    &__text {
        margin: pxToRem(32px) 0 0 pxToRem(-24px);

        @include media-breakpoint-down(md) {
            margin: 0 pxToRem(30px) 0 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 30px 0 0 0;
        }

        & > * {
            margin-bottom: 0;
        }

        & > * + * {
            margin-top: 17.865%;

            @include media-breakpoint-down(lg) {
                margin-top: pxToRem(30px);
            }
        }
    }

    &__list-with-icons {
        margin: pxToRem(33px) 0 0 pxToRem(-9px);

        @include media-breakpoint-down(sm) {
            margin-top: 50px;
        }
    }

    &__header {
        display: none;
        text-align: center;
        padding-bottom: 50px;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    &__header-2 {

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}