$layouts: (
    two-thirds: 0.5875,
    fifty-fifty: 0.5,
    one-third: 0.354,
);

@each $name, $value in $layouts {

    @each $breakpoint in map-keys($grid-breakpoints) {

        /*.container {

            @include media-breakpoint-down($breakpoint) {
                max-width: map_get($page-max-widths, $breakpoint);
            }
        }//*/

        .section-layout-#{$name} {

            @include media-breakpoint-up($breakpoint) {
                max-width: map_get($page-max-widths, $breakpoint);
            }

            .section-left {

                .left-container {
                    @include media-breakpoint-up($breakpoint) {
                        max-width: map_get($page-max-widths, $breakpoint) * $value - (map_get($page-max-widths, $breakpoint) - map_get($container-max-widths, $breakpoint)) / 2 + $grid-gutter-width / 2;
                    }
                }
            }

            .section-right {

                .right-container {
                    @include media-breakpoint-up($breakpoint) {
                        max-width: map_get($container-max-widths, $breakpoint) - (map_get($page-max-widths, $breakpoint) * $value - (map_get($page-max-widths, $breakpoint) - map_get($container-max-widths, $breakpoint)) / 2) + $grid-gutter-width / 2;
                    }
                }
            }
        }
    }

    .section-layout-#{$name} {
        width: 100%;
        height: 100%;
        display: flex;
        margin: 0 auto;
        //background: rgba($teal, .3);

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .section-left {
            width: $value * 100%;
            //background-color: rgba(green, .3);

            .left-container {
                margin-left: auto;
                width: 100%;
                //background: rgba(red, .3);
                padding-left: ($grid-gutter-width / 2);

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    padding-right: ($grid-gutter-width / 2);
                    margin-left: unset;
                    max-width: 400px;
                    margin: 0 auto;
                }
            }
        }

        .section-right {
            width: (1 - $value) * 100%;
            //background-color: rgba(red, .3);

            .right-container {
                margin-right: auto;
                width: 100%;
                //background: rgba(green, .3);
                padding-right: ($grid-gutter-width / 2);

                @include media-breakpoint-down(sm) {
                    padding-left: ($grid-gutter-width / 2);
                    margin-right: unset;
                    max-width: unset;
                }
            }
        }

        .section-left,
        .section-right {
            height: 100%;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}

.section {
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        height: auto !important;
    }

    .fp-responsive & {

        &,
        & .section-left,
        & .section-right {
            min-height: 100vh;
            height: auto !important;

            @include media-breakpoint-down(sm) {
                min-height: unset;
            }
        }
    }
}