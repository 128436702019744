/* ==========================================================================
   Vendors
   ========================================================================== */

@import "./../../bower_components/swiper/dist/css/swiper.min";

.swiper-button-prev,
.swiper-button-next {
    background-image: none;
    margin-top: -53px;
    width: auto;
    height: auto;

    @include media-breakpoint-down(md) {
        margin-top: #{-53px / 1.5};
    }

    &:hover {

        .icon {
            opacity: .8;
        }
    }

    .icon {
        @include transition(500ms);
        fill: $gray-600;
        width: 45px;
        height: 51px;

        @include media-breakpoint-down(sm) {
            width: 30px;
            height: 34px;
        }
    }
}

.swiper-button-prev {
    left: 0;

    @include media-breakpoint-down(xs) {
        left: 10px;
    }
}

.swiper-button-next {
    right: 0;
    left: auto;

    @include media-breakpoint-down(xs) {
        right: 10px;
    }
}

.swiper-pagination {
    bottom: 13px !important;

    &_standalone {
        position: static;
        padding: 62px 0 0;

        @include media-breakpoint-down(sm) {
            padding-top: 40px;
        }
    }

    .swiper-pagination-bullet {
        background: $white;
        @include circle(11px);
        margin: 0 14px !important;
    }

    .swiper-pagination-bullet-active {

    }
}

@import  "./../../bower_components/magnific-popup/dist/magnific-popup";

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close,
.mfp-close {
    @include circle(pxToRem(39px));
    display: block;
    background: $yellow;
    color: $black;
    text-align: center;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 0 rgba($yellow, 0);
    @include transition(500ms);
    z-index: 1060;
    opacity: 1;
    line-height: pxToRem(42px);
    top: pxToRem(20px);
    font-size: pxToRem(28px);
    right: pxToRem(20px);

    &:hover,
    &:focus,
    &:active {
        box-shadow: 0 0 0 pxToRem(10px) rgba($yellow, .2);
        top: pxToRem(20px);
    }
}

@import "./../../bower_components/fullpage.js/dist/jquery.fullpage.min";

@import "./../../bower_components/jquery-ui-slider/jquery-ui.structure";

.ui-slider {

    &.ui-slider-horizontal {
        @include block(100%, 5px);
        background: rgba($black, .14);

        .ui-slider-handle {
            @include circle(43px);
            background: $yellow;
            top: -21px;
            margin-left: -21px;
            overflow: visible;
            outline: 0 none;
            font: $font-weight-medium 23px $font-family-base;
            white-space: nowrap;
            color: $gray-800;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @include media-breakpoint-down(sm) {
                @include circle(30px);
                top: -15px;
                margin-left: -15px;
                font-size: 16px;
                line-height: 34px;
            }
        }

        .ui-slider-range {
            height: 5px;
            top: 0;

            //margin: 0 0 0 2px;
            background: $red;
        }
    }
}

.slider-ruler {
    display: flex;
    margin: 0 -8px;
    justify-content: space-between;
    font: $font-weight-medium pxToRem(16px) $font-family-base;
    color: rgba($black, .34);

    > span {
        display: inline-block;
        width: 16px;
        position: relative;
        padding-top: 47px;
        text-align: center;

        @include media-breakpoint-down(lg) {
            padding-top: 30px;
        }

        &:before {
            @include block(1px, 26px);
            @include absoluteTL(16px, 50%);
            margin-left: -1px;
            content: '';
            background: rgba($black, .34);

            @include media-breakpoint-down(lg) {
                height: 10px;
            }
        }
    }
}

@import "./../../bower_components/select2/dist/css/select2.min";