$circle-size: 551px;

.iphone-formula {
    position: relative;
    z-index: 20;

    &:before {
        @include circle(pxToRem($circle-size));
        background: $red;
        content: '';
        z-index: -1;
        display: block;
        @include absoluteTL(50%, 50%);
        margin: pxToRem(-$circle-size / 2) 0 0 pxToRem(-$circle-size / 2);

        @include media-breakpoint-down(xl) {
            @include circle(pxToRem($circle-size * .9));
            margin: pxToRem(-$circle-size * .9 / 2) 0 0 pxToRem(-$circle-size * .9 / 2);
        }

        @include media-breakpoint-down(lg) {
            @include circle(pxToRem($circle-size * .8));
            margin: pxToRem(-$circle-size * .8 / 2) 0 0 pxToRem(-$circle-size * .8 / 2);
        }

        @include media-breakpoint-down(md) {
            @include circle(pxToRem($circle-size * .6));
            margin: pxToRem(-$circle-size * .6 / 2) 0 0 pxToRem(-$circle-size * .6 / 2);
        }

        @include media-breakpoint-down(sm) {
            @include circle(pxToRem(340px));
            margin: pxToRem(-170px) 0 0 pxToRem(-170px);
        }
    }

    &__image {
        @include block(100%, auto);
        max-height: 100%;

        @include media-breakpoint-down(sm) {
            max-height: 300px;
            width: auto;
        }
    }

    &__small-logo {
        @include absoluteTR(pxToRem(139px), 0);

        @include media-breakpoint-down(sm) {
            @include absoluteTR(pxToRem(90px), 0);
        }
    }
}