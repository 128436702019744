.videos {
    position: relative;
    padding: 19.8vh 0 0 0;

    @include media-breakpoint-down(xl) {
        padding-bottom: 4vh;
    }

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
        text-align: center;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(100px) 0 pxToRem(100px);
    }

    &:before {
        @include absoluteTR(15%, 0);
        @include block(64%, auto);
        bottom: 23%;
        content: '';
        z-index: 0;
        @include background-dots($dark-red);

        @include media-breakpoint-down(sm) {
            bottom: 5%;
        }
    }

    &__about {
        padding: 0 pxToRem(223px) 0 0;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        > * + * {
            margin-top: pxToRem(30px);
        }
    }

    &__video-big {
        width: 77%;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}

.videos-swiper {
    width: 100%;
    height: 75.1%;
    margin-top: 6.7%;

    background: $gray-100;

    .swiper-slide {
        height: #{100% / 3};
        background: $gray-600;

        @include media-breakpoint-down(lg) {
            height: #{100% / 2};
        }

        @include media-breakpoint-down(sm) {
            height: 100%;
        }

        img {
            @include block(100%, 100%);
            object-fit: cover;
        }
    }

    .video-slide {
        @include block(100%, 100%);
        position: relative;

        &:before {
            @include block(100%, 100%);
            @include absoluteTL(0, 0);
            content: '';
            background: rgba($white, .4);
            z-index: 20;
            opacity: 1;
            transition: all 500ms;
        }

        .play {
            transition: all 500ms;
        }

        &:hover {

            &:before {
                opacity: 0;
            }

            .play {
                opacity: .7;

                svg {
                    fill: $dark-red;
                }
            }
        }
    }
}

.play {
    @include absoluteTL(0, 0);
    @include block(100%, 100%);
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-play {
        display: block;
        line-height: 0;

        svg {
            width: pxToRem(62px);
            height: pxToRem(62px);
            fill: $white;
        }
    }
}

.videos-swiper-container {
    @include absoluteTL(0, 0);
    z-index: 300;
    @include block(100%, 100%);
    display: none;
    background: rgba($blue, .4);

    img {
        @include block(100%, 100%);
        object-fit: cover;
    }

    iframe {
        @include block(100%, 100%);
    }
}

.video-big {
    position: relative;

    img {
        @include block(100%, 100%);
        object-fit: cover;
    }

    .play {

        svg {
            width: pxToRem(89px);
            height: pxToRem(89px);
        }
    }

}

.video-close {
    display: none;
}

.videos-swiper-loader {
    @include absoluteTL(50%, 50%);
    position: absolute !important;
    margin: #{-$loader-size / 2} 0 0 #{-$loader-size / 2};
    z-index: 100;
    display: none;
}