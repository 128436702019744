.doc {
    position: relative;
    background: $gray-150;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    &:before {
        @include absoluteTR(25%, 0);
        @include block(59%, auto);
        bottom: 6%;
        content: '';
        z-index: 1;
        @include background-dots($blue-dark);
    }

    .section-left,
    .section-right {
        padding-top: 17.2vh;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        @include media-breakpoint-no-pages-up(sm) {
            padding: pxToRem(200px) 0 pxToRem(200px);
        }
    }

    &__left-right {
        position: relative;

        @include media-breakpoint-down(sm) {
            padding: 0 25px !important;
        }

        &:before {
            @include absoluteTL(50%, -69.07%);
            margin-top: -48.54%;
            @include block(115%, 78.43%);
            content: '';
            background: url('../images/doc.png') 0 0 no-repeat;
            background-size: contain;
            z-index: 2;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    &__about {
        padding: 0 55% 0 0;
        position: relative;
        z-index: 10;

        @include media-breakpoint-down(xxl) {
            padding-right: 50%;
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        > * {
            margin-bottom: pxToRem(20px);
        }
    }

    &__wallet {

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
        }

        .slider-line {
            margin: pxToRem(79px) 0 0 0;
        }
    }
}