.meal-item {
    font: $font-weight-base pxToRem(17px)/1.29 $font-family-base;
    color: $gray-800;

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
        @include clearfix;
    }

    &__box {
        box-shadow: 0 pxToRem(10px) pxToRem(20px) 0 rgba(0, 0, 0, .25);
        overflow: hidden;
        position: relative;

        img {
            @include block(100%, 100%);
        }

        @include media-breakpoint-down(sm) {
            margin-right: pxToRem(25px);
            margin-bottom: pxToRem(25px);
            float:left;
        }

        @include media-breakpoint-down(xs) {
            margin-bottom: pxToRem(40px);
        }
    }

    &__shape-rectangle {
        @include block(pxToRem(200px), pxToRem(141px));
        border-radius: pxToRem(28px);
/*
        @media (max-width: $screen-lg) {
            @include block(160px, 116px);
        }
*/
        @include media-breakpoint-down(sm) {
            @include block(pxToRem(120px), pxToRem(87px));
        }
    }

    &__shape-circle {
        @include circle(pxToRem(145px));
/*
        @media (max-width: $screen-lg) {
            @include circle(116px);
        }*/

        @include media-breakpoint-down(sm) {
            @include circle(87px);
        }
    }

    &__shape-square {
        @include block(pxToRem(145px), pxToRem(145px));
        border-radius: pxToRem(28px);
/*
        @media (max-width: $screen-lg) {
            @include block(116px, 116px);
        }*/

        @include media-breakpoint-down(sm) {
            @include block(pxToRem(87px), pxToRem(87px));
        }
    }

    &__shape-bottle {
        //@include block(pxToRem(95px), pxToRem(193px));
        @include block(pxToRem(71px), pxToRem(145px));
        border-radius: 0;
        box-shadow: unset;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        overflow: visible;
        margin-bottom: pxToRem(15px);

        @include media-breakpoint-down(sm) {
            margin-left: pxToRem(30px);
            margin-right: pxToRem(50px);
        }

        img {
            position: relative;
            z-index: 2;
            @include block(100%, 100%);
            //width: pxToRem(95px);
            //height: pxToRem(193px);
        }

        & + .meal-item__about {

            @include media-breakpoint-down(sm) {
                margin-left: pxToRem(120px);
                font-size: pxToRem(27px);
            }
        }
    }

    &__hot,
    &__cold {
        display: block;
    }

    &__hot {
        @include sprite($hot);
    }

    &__cold {
        @include sprite($cold);
    }

    &__need-to-warm {
        position: absolute;
        margin-top: -$hot-height;
        z-index: 40;

        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }

    &__title {
        padding: 2.55vh 0 .814vh;
        font: $font-weight-extra-bold pxToRem(21px)/1 $font-family-base;

        @include media-breakpoint-down(sm) {
            font-size: pxToRem(20px);
            padding: 0 0 pxToRem(5px);
        }
    }

    &__about {
        height: 8.8vh;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            height: auto;
            max-height: 74px;
        }

    }

    &__elements {
        padding: pxToRem(11px) 0 0;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            display: block;
        }

        > span {
            font: $font-weight-medium pxToRem(12px)/1.66 $font-family-base;
            padding: pxToRem(2px) pxToRem(6px) 0;
            border: 1px solid $gray-600;
            display: inline-block;
            border-radius: pxToRem(8px);
            color: $gray-600;
            margin: 0 pxToRem(2px) pxToRem(6px) 0;
        }
    }
}
/*
.meal-vegan .meal-item__shape-bottle {
    @include block(102px, 145px);
    margin-bottom: 0;

    /*@media (max-width: $screen-md) {
        margin-left: 15px;
        margin-right: 15px;
    }
*/
 /*   img {
        position: relative;
        z-index: 2;
        width: 71px;
        height: 145px;
        margin: 0 auto;
    }
}
*/
.menu-slide {
    padding: 0 pxToRem(10px);

    &.slide-loss {

        .meal-gain,
        .meal-detox,
        .meal-vegan {
            display: none;
        }

        .meal-loss {
            display: block;
        }
    }

    &.slide-gain {

        .meal-loss,
        .meal-detox,
        .meal-vegan {
            display: none;
        }

        .meal-gain {
            display: block;
        }
    }

    &.slide-detox {

        .meal-gain,
        .meal-loss,
        .meal-vegan {
            display: none;
        }

        .meal-detox {
            display: block;
        }
    }

    &.slide-vegan {

        .meal-gain,
        .meal-loss,
        .meal-detox {
            display: none;
        }

        .meal-vegan {
            display: block;
        }
    }

    .col {

        @include media-breakpoint-down(sm) {
            flex-basis: unset;
        }
    }
}

.menu-swiper {
    padding: 7vh 0 0;
    max-width: 1194px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        padding-top: 30px;
    }
}