.what-you-get {
    position: relative;
    padding-top: 17.2vh;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(100px) 0 pxToRem(100px);
    }

    &:before {
        @include absoluteTR(27.6%, 0);
        @include block(68.8%, auto);
        bottom: 5.1%;
        content: '';
        z-index: -1;
        @include background-dots($orange);
    }

    &__about {
        margin: 48px 0 0 0;
        padding: 0 30px 0 0;

        @include media-breakpoint-down(sm) {
            margin: 0;
            padding: 0;
        }
    }

    &__buttons {
        padding: pxToRem(45px) 0 pxToRem(47px) pxToRem(11px);
    }

    &__text {

        > * {
            margin-bottom: 19px;
        }
    }
}