.wallet {
    width: 100%;
    max-width: 88.8%;
    position: relative;
    margin-left: auto;
    text-align: left;

    @include media-breakpoint-down(sm) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    &-content {
        background: $white;
        box-shadow: 0 pxToRem(3px) pxToRem(10px) 0 rgba($black, 0.07);
        border-radius: 40px 0 0 40px;
        padding: pxToRem(58px) pxToRem(56px);
        position: relative;
        z-index: 15;

        @include media-breakpoint-down(xl) {
            padding: pxToRem(40px);
        }

        @include media-breakpoint-down(md) {
            padding: pxToRem(30px);
        }

        @include media-breakpoint-down(sm) {
            border-radius: 40px;
            padding: 25px 20px;
        }
    }

    &:before,
    &:after {
        content: '';
        z-index: 10;
        position: absolute;
        border-radius: 40px 0 0 40px;
        background: $white;

        @include media-breakpoint-down(sm) {
            border-radius: 40px;
        }
    }

    &:before {
        top: pxToRem(-10px);
        left: pxToRem(-10px);
        bottom: pxToRem(-10px);
        right: 0;
        box-shadow: 0 pxToRem(3px) pxToRem(10px) 0 rgba($black, 0.07);

        @include media-breakpoint-down(sm) {
            right: pxToRem(-10px);
        }
    }

    &:after{
        top: pxToRem(-19px);
        left: pxToRem(-19px);
        bottom: pxToRem(-19px);
        right: 0;
        z-index: 4;

        @include media-breakpoint-down(sm) {
            right: pxToRem(-19px);
        }
    }

    &__form {
        max-width: pxToRem(490px);
    }
}