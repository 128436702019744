.business {

    @include media-breakpoint-down(sm) {
        padding: 50px 0 0;
    }

    .section-left,
    .section-right {
        padding-top: 17.2vh;
        position: relative;

        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }

        @include media-breakpoint-no-pages-up(sm) {
            padding: pxToRem(200px) 0 0;
        }
    }

    .section-left {
        overflow: hidden;

        &:before {
            @include absoluteTR(0, 0);
            @include block(32%, 48%);
            content: '';
            z-index: 2;
            @include background-dots($yellow);
        }

        &:after {
            @include absoluteTR(1.5%, -.7%);
            @include block(40.2%, 55.1%);
            content: '';
            z-index: 1;
            background: url('../images/salad-2.jpg') 0 0 no-repeat;
            background-size: cover;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .section-right {

        @include media-breakpoint-down(sm) {
            padding: 30px 25px !important;
        }
    }

    &__left-back {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(lg) {
            align-items: flex-end;
        }
    }

    &__about {
        padding: 0 43% 0 0;
        position: relative;
        z-index: 10;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }

        > * + * {
            margin-top: pxToRem(20px);
        }
    }

    &__wallet {}

    &__reasons-list {
        position: relative;
        z-index: 10;
        margin: pxToRem(50px) pxToRem(56px) pxToRem(50px) 0;

        @include media-breakpoint-down(xl) {
            margin: pxToRem(30px) pxToRem(40px) pxToRem(30px) 0;
        }

        @include media-breakpoint-down(md) {
            margin: pxToRem(20px) pxToRem(20px) pxToRem(20px) 0;
        }

        @include media-breakpoint-down(sm) {
            margin: 30px 0 10px;
        }
    }
}

.page-comment {
    color: rgba($black, .51);
    font: $font-weight-normal pxToRem(13px)/1.615 $font-family-base;
}