.swiper-buttons {
    padding: 5.56vh 0 10px pxToRem(15px);
    display: flex;

    @include media-breakpoint-down(sm) {
        padding: 20px;
    }

    > * {
        margin-right: pxToRem(37px);
    }
}