.products {
    padding: 20.33vh 0 0 0;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(100px) 0 pxToRem(200px);
    }

    &:before {
        @include absoluteTL(0, 14%);
        display: block;
        bottom: 56%;
        right: 13%;
        content: '';
        z-index: -1;
        @include background-dots($blue);
        opacity: .1;
    }

    .h5, h5 {
        max-width: 470px;
        margin: 2.55vh auto 4.88vh;
    }
}