.tasks {
    padding: 25vh 0 0 0;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
        text-align: center;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(100px) 0 pxToRem(200px);
    }

    &:before {
        @include absoluteTL(26.6%, 0);
        @include block(67.8%, auto);
        bottom: 10%;
        content: '';
        z-index: 20;
        @include background-dots($pink);

        @include media-breakpoint-down(xl) {
            width: 68.4%;
        }

        @include media-breakpoint-down(sm) {
            top: 0;
            left: 10%;
            right: 10%;
            width: auto;
            height: 50%;
        }
    }

    &:after {
        @include absoluteBL(0, 50%);
        @include block(pxToRem(784px), pxToRem(1100px));
        margin-left: pxToRem(-784px/2);
        content: '';
        z-index: 0;
        background: url('../images/salad.jpg');
        background-size: cover;

        @media (min-width: 2400px) {
            transform: scale(1.4);
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__container {
        position: relative;
        z-index: 30;
    }

    &__list-with-icons {
        padding-right: pxToRem(50px);

        @include media-breakpoint-down(md) {
            padding-right: 0;
        }
    }

    &__big-logo {
        position: relative;
        z-index: 20;
        margin: pxToRem(43px) 0 0 pxToRem(-24px);
/*
        @include media-breakpoint-down(sm) {
            margin: 0 auto;
            display: none !important;
        }
// */
        @include media-breakpoint-down(sm) {
            margin: 0 auto;
            display: none !important;
        }
    }

    &__problems-list {
        padding: 2vh 0;

        @include media-breakpoint-down(sm) {
            max-width: 300px;
            margin: 0 auto;
        }
    }

    &__after {

        @include media-breakpoint-down(xl) {
            display: none;
        }

        @include media-breakpoint-down(sm) {
            display: block;
            max-width: 350px;
            margin: 0 auto;
        }
    }
}