.btn {
    @extend .btn;

    &:hover {
        cursor: pointer;
    }
}

@mixin button-shadow($color) {

    box-shadow: 0 pxToRem(7px) pxToRem(20px) 0 $color;
}

// custom shadows
@each $color, $value in $theme-colors {
    .btn-#{$color} {

        &:hover,
        &:focus,
        &:active {
            @include button-shadow($value);
        }
    }
}

@each $color, $value in $colors {

    .btn-#{$color} {
        @include button-variant($value, $value);
    }

    .btn-#{$color} {

        &:hover,
        &:focus,
        &:active {
            @include button-shadow($value);
        }
    }
}

.btn-teal {
    color: $white;
}

.btn-group {
    background: $gray-300;
    border-radius: pxToRem(50px);

    @include media-breakpoint-down(sm) {
        display: block;
        border-radius: 0;
        background: unset;
        padding: 0;
    }

    .btn {

        &:hover,
        &:active,
        &:focus {
            box-shadow: unset;
        }
    }

    .btn:not(:last-child):not(.dropdown-toggle), > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: pxToRem(50px);
        border-bottom-right-radius: pxToRem(50px);

        @include media-breakpoint-down(sm) {
            border-radius: 0;
        }
    }

    .btn:not(:first-child) {
        border-top-left-radius: pxToRem(50px);
        border-bottom-left-radius: pxToRem(50px);

        @include media-breakpoint-down(sm) {
            border-radius: 0;
        }
    }

    .btn:first-child {

        @include media-breakpoint-down(sm) {
            border-radius: pxToRem(50px) pxToRem(50px) 0 0 !important;
        }
    }

    .btn:last-child {

        @include media-breakpoint-down(sm) {
            border-radius: 0 0 pxToRem(50px) pxToRem(50px);
        }
    }

    > .btn {
        font-size: pxToRem(21px);
        padding: pxToRem(18px) pxToRem(35px);
        background: $gray-300;

        @include media-breakpoint-down(sm) {
            width: 100%;
            border-radius: 0;
        }

        &.active {
            background: $blue;
            color: $white;
        }
    }
}