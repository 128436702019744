.price {
    position: relative;
    display: inline-block;
    color: $black;
    font: $font-weight-bold pxToRem(34px) $font-family-base;

    @include media-breakpoint-down(sm) {
        font-size: 22px;
    }

    > span {
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    &:before {
        content: '';
        @include absoluteTL(pxToRem(-12px), pxToRem(-14px));
        bottom: pxToRem(-10px);
        right: pxToRem(-16px);
        background: $yellow;
        z-index: 0;
    }
}