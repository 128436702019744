.popup {
    width: 100%;
    max-width: pxToRem(607px);
    position: relative;
    margin: 0 auto;

    &-content {
        background: $white;
        box-shadow: 0 pxToRem(3px) pxToRem(10px) 0 rgba($black, 0.07);
        border-radius: pxToRem(40px);
        padding: pxToRem(58px) pxToRem(56px);
        position: relative;
        z-index: 15;

        @include media-breakpoint-down(sm) {
            padding: pxToRem(40px) pxToRem(30px) pxToRem(30px);
        }

        @include media-breakpoint-down(xs) {
            padding: pxToRem(30px);
        }
    }

    &:before,
    &:after {
        content: '';
        z-index: 10;
        position: absolute;
        border-radius: pxToRem(40px);
        background: $white;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &:before {
        top: pxToRem(-10px);
        left: pxToRem(-10px);
        bottom: pxToRem(-10px);
        right: pxToRem(-10px);
        box-shadow: 0 pxToRem(3px) pxToRem(10px) 0 rgba($black, 0.07);
    }

    &:after{
        top: pxToRem(-19px);
        left: pxToRem(-19px);
        bottom: pxToRem(-19px);
        right: pxToRem(-19px);
        z-index: 4;
    }

    p {
        font: $font-weight-base pxToRem(20px)/1.647 $font-family-base;
    }
}

.popup-success {
    padding: pxToRem(46px) 0 pxToRem(65px);
    text-align: center;

    @include media-breakpoint-down(sm) {
        padding: 0;
    }

    &__icon {
        @include circle(pxToRem(73px));
        display: inline-block;
        background: $gray-100;
        overflow: visible;
        margin-bottom: pxToRem(24px);
        margin-left: pxToRem(-15px);
    }
}

.popup-header {
    font: $font-weight-extra-bold pxToRem(26px)/1.22 $font-family-base;
    padding-bottom: pxToRem(26px);
    letter-spacing: .4px;
}