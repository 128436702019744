/* ==========================================================================
   Bootstrap debug
   ========================================================================== */
// you can remove it later
.debug {
    position: fixed;
    top: 0;
    left: 0;
    background: #0c0;
    z-index: 10000;
    color: $white;
    text-align: center;

    > span {
        border-top: 1px dotted;
        display: block;
        padding: 3px;
    }
}

.debug-box {
    @include block(100%, 200px);
    background: transparentize(#00c, .8);
}