.terms {
    position: relative;
    padding-top: 17.2vh;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(200px) 0 pxToRem(200px);
    }

    &:before {
        @include absoluteTR(26%, 0);
        @include block(74%, auto);
        bottom: 12%;
        content: '';
        z-index: -1;
        @include background-dots($red);
    }

    &__head {
        display: flex;
        position: relative;
        z-index: 30;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    &__header {
        padding-top: pxToRem(19px);
    }

    &__forecast {
        margin-left: auto;

        @include media-breakpoint-down(sm) {
            margin-top: 15px;
        }
    }

    &__cities {
        margin-top: pxToRem(39px);
    }
}