.map {
    position: relative;
    padding-top: 22.5vh;

    @include media-breakpoint-down(sm) {
        padding: 50px 0 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(200px) 0 pxToRem(200px);
    }
}

.map-container {
    @include absoluteBL(0, 0);
    @include block(100%, auto);
    top: 36%;
    background: $gray-800;
    z-index: 20;

    @include media-breakpoint-down(sm) {
        position: static;
        top: unset;
        height: 300px;
        margin-top: 40px;
    }
}

.map-info {
    background: $white;
    border-radius: pxToRem(53px);
    box-shadow: 0 pxToRem(3px) pxToRem(30px) 0 rgba($black, .08);
    @include absoluteTL(pxToRem(-62px), 0);
    right: 0;
    z-index: 30;
    display: flex;
    padding: pxToRem(34px) pxToRem(52px);
    font: $font-weight-medium pxToRem(16px)/1.1875 $font-family-base;
    color: rgba($black, .34);
    margin: 0 pxToRem($grid-gutter-width / 2);

    @include media-breakpoint-down(lg) {
        padding: pxToRem(20px) pxToRem(30px);
        top: -30px;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }

    > * + * {
        margin-left: pxToRem(37px);
    }

    > .city-item + .city-item {
        margin-left: pxToRem(13px);
    }
}

.map-layer {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 20;

    @include media-breakpoint-no-pages-up(sm) {
        height: 600px;
    }
}

.city-item {
    border: 1px solid $gray-600;
    border-radius: pxToRem(8px);
    font: $font-weight-medium pxToRem(16px) $font-family-base;
    height: pxToRem(36px);
    padding: pxToRem(2px) pxToRem(13px) 0 pxToRem(7px);
    display: flex;
    align-items: center;
    align-content: center;
    color: $gray-600;

    .icon-pin-green,
    .icon-pin-red {
        margin-right: pxToRem(7px);
    }
}

.map-balloon {
    background: $white;
    font: $font-weight-base $font-size-base $font-family-base;
    color: $gray-800;
    text-align: center;

    &__header {}

    &__city {
        font-size: pxToRem(16px);
        font-weight: $font-weight-medium;
        color: $black;
        padding: pxToRem(20px) 0;

        *[class*="icon-pin-"] {
            vertical-align: -4px;
        }
    }

    &__button {}
}