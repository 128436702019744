@font-face {
	font-family: 'Ruble';
	src: url('../fonts/alsrubl-webfont.eot');
	src: url('../fonts/alsrubl-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/alsrubl-webfont.woff2') format('woff2'),
	url('../fonts/alsrubl-webfont.woff') format('woff'),
	url('../fonts/alsrubl-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'fira_sans';
	src: url('../fonts/firasans-light-webfont.eot');
	src: url('../fonts/firasans-light-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/firasans-light-webfont.woff2') format('woff2'),
	url('../fonts/firasans-light-webfont.woff') format('woff'),
	url('../fonts/firasans-light-webfont.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

/*@font-face {
	font-family: 'fira_sans';
	src: url('../fonts/firasans-regular-webfont.eot');
	src: url('../fonts/firasans-regular-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/firasans-regular-webfont.woff2') format('woff2'),
	url('../fonts/firasans-regular-webfont.woff') format('woff'),
	url('../fonts/firasans-regular-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}// */

@font-face {
	font-family: 'fira_sans';
	src: url('../fonts/firasans-book-webfont.eot');
	src: url('../fonts/firasans-book-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/firasans-book-webfont.woff2') format('woff2'),
	url('../fonts/firasans-book-webfont.woff') format('woff'),
	url('../fonts/firasans-book-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'fira_sans';
	src: url('../fonts/firasans-medium-webfont.eot');
	src: url('../fonts/firasans-medium-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/firasans-medium-webfont.woff2') format('woff2'),
	url('../fonts/firasans-medium-webfont.woff') format('woff'),
	url('../fonts/firasans-medium-webfont.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'fira_sans';
	src: url('../fonts/firasans-bold-webfont.eot');
	src: url('../fonts/firasans-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/firasans-bold-webfont.woff2') format('woff2'),
	url('../fonts/firasans-bold-webfont.woff') format('woff'),
	url('../fonts/firasans-bold-webfont.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'fira_sans';
	src: url('../fonts/firasans-semibold-webfont.eot');
	src: url('../fonts/firasans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/firasans-semibold-webfont.woff2') format('woff2'),
	url('../fonts/firasans-semibold-webfont.woff') format('woff'),
	url('../fonts/firasans-semibold-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'fira_sans';
	src: url('../fonts/firasans-extrabold-webfont.eot');
	src: url('../fonts/firasans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/firasans-extrabold-webfont.woff2') format('woff2'),
	url('../fonts/firasans-extrabold-webfont.woff') format('woff'),
	url('../fonts/firasans-extrabold-webfont.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}