.first {
    color: $gray-700;
    font-size: pxToRem(30px);
    font-weight: $font-weight-medium;
    line-height: 1.4666;

    @include media-breakpoint-down(sm) {
        text-align: center;
        font-size: 13px;
        color: $gray-700;
    }

    .section-left,
    .section-right {
        padding-top: 20vh;

        @include media-breakpoint-no-pages-up(sm) {
            padding: pxToRem(250px) 0 pxToRem(150px);
        }
    }

    .section-right {

        @include media-breakpoint-down(sm) {
            padding: 50px 25px !important;
        }
    }

    &__wallet {}

    &__price {
        margin-left: pxToRem(16px);

        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }

    &__left-back {
        position: relative;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            padding-bottom: 100px;
        }

        &:before {
            @include absoluteBR(0, 0);
            @include block(58.8%, 73.7%);
            content: '';
            z-index: 2;
            @include background-dots($yellow);

            @include media-breakpoint-down(sm) {

                left: 0;
                right: 0;
                height: 260px;
                width: 100%;
            }
        }

        &:after {
            @include absoluteBR(0, 0);
            @include block(50%, 100%);
            content: '';
            z-index: 1;
            background: url('../images/boxes-3.jpg');
            background-size: cover;

            @include media-breakpoint-down(sm) {
                width: 30%;
            }

            @include media-breakpoint-down(xxs) {
                display: none;
            }
        }
    }

    &__left-container {
        position: relative;
        z-index: 10;
    }

    &__about {

        .display-3 {
            color: $black;
            margin-bottom: pxToRem(41px);

            @include media-breakpoint-down(sm) {
                margin-bottom: 10px;
            }
        }

        p {
            margin-bottom: pxToRem(28px);

            @include media-breakpoint-down(sm) {
                margin-bottom: 30px;
            }
        }
    }
}