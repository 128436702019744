.float-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 250;
    padding: 12vh 0 0 0;

    @include media-breakpoint-down(lg) {
        padding-top: 10vh;
    }

    @include media-breakpoint-down(md) {
        padding-top: 7vh;
    }

    @include media-breakpoint-down(sm) {
        padding: 36px 0 0 0;
        justify-content: space-between;
    }

    &, & * {

        &, &:after, &:before {
            transition: all 500ms;
        }
    }

    &__container {
        position: relative;
        display: flex;
    }

    &__logo {

        @include media-breakpoint-down(sm) {
            order: 2;
            margin: -24px auto 0;
        }
    }

    &__phone {
        margin-left: auto;
        margin-right: pxToRem(25px);

        @include media-breakpoint-down(sm) {
            order: 3;
            margin: 0;
        }
    }

    &__button {

        @include media-breakpoint-down(sm) {
            order: 1;
        }

        .btn {
            background: $red;
            color: $white;
            padding: pxToRem(28px) pxToRem(31px);
            line-height: 1.2;
            font-size: pxToRem(19px);
            font-weight: $font-weight-medium;

            @include media-breakpoint-down(md) {
                padding: pxToRem(16px) pxToRem(20px);
            }

            &:hover {
                @include button-shadow($red);
            }
        }
    }

    &--mini {
        padding-top: 7.8vh;

        @include media-breakpoint-down(lg) {
            padding-top: 6vh;
        }

        @include media-breakpoint-down(sm) {
            padding: 20px 0 0;
        }

        .float-header__phone {
            //margin-right: pxToRem(34px);

            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }
    }

    &--mini.float-header--no-pages {

        @include media-breakpoint-up(md) {
            background: #fff;
            box-shadow: 0 10px 20px 0 rgba($black, .2);
            padding: 1vh 0;
        }
    }

    // header theming
    @each $color, $value in $colors {

        &--theme-#{$color} {

            .float-header__button .btn,
            .pdf__mobile-btn,
            .phone__mobile-btn,
            .logo__image:after {
                background: $value;
            }

            .float-header__button .btn:hover {
                @include button-shadow($value);
            }

            .float-header__phone .phone__callback {

                &,
                &:hover,
                &:focus {
                    color: $value;
                }
            }
        }
    }
}

.phone {
    font-weight: $font-weight-medium;
    font-size: pxToRem(31px);
    text-align: right;
    padding: pxToRem(14px) 0 0 0;
    line-height: 1.48;
    color: $black;

    @include media-breakpoint-down(md) {
        padding: pxToRem(4px) 0 0 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
        line-height: 0;
        text-align: left;
    }

    &__number {

        &, & a {
            color: $black;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__callback {
        display: block;
        font-size: pxToRem(18px);
        color: $red;
        padding-right: pxToRem(5px);
        transition: all 500ms;

        &,
        &:hover,
        &:focus {
            color: $red;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__mobile-btn {
        @include circle(56px);
        background: $red;
        display: none;
        align-items: center;
        justify-content: center;
        align-content: center;

        @include media-breakpoint-down(sm) {
            display: flex;
        }
    }
}

.pdf {

    &__btn {

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__mobile-btn {
        @include circle(56px);
        background: $yellow;
        display: none;
        align-items: center;
        justify-content: center;
        font: $font-weight-bold 19px/0 $font-family-base;
        padding-top: 2px;

        @include media-breakpoint-down(sm) {
            display: flex;
        }

        &, &:active, &:focus, &:hover {
            color: $black;
        }
    }
}