.problems-list {
    @extend .list-unstyled;
    text-align: left;

    li {
        font: $font-weight-base pxToRem(17px)/1.647 $font-family-base;
        max-width: 300px;
    }

    li + li {
        margin-top: 2vh;
    }

    .li-header {
        display: flex;
        align-items: center;
        align-content: center;
        font: $font-weight-extra-bold pxToRem(21px) $font-family-base;
        color: $pink;
        padding-bottom: pxToRem(7px);
        letter-spacing: .6px;

        &__circle {
            @include circle(pxToRem(50px));
            background: $pink;
            color: #fff;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin-right: pxToRem(10px);
            font-size: pxToRem(17px);
        }
    }
}