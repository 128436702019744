.notification {
    position: fixed;
    bottom: pxToRem(30px);
    right: pxToRem(30px);
    padding: pxToRem(10px) pxToRem(20px) pxToRem(10px) pxToRem(83px);
    width: pxToRem(390px);
    border-radius: pxToRem(10px);
    background: rgba($black, .8);
    text-align: left;
    font: 400 pxToRem(18px)/1.66 $font-family-base;
    color: $gray-500;
    display: none;
    z-index: 2000;

    @include media-breakpoint-down(sm) {
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 0;
        padding: pxToRem(10px);
        width: auto;
    }

    &:before {
        @include sprite($mail);
        content: '';
        display: block;
        @include absoluteTL(20px, 20px);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .bright {
        color: $white;

        @include media-breakpoint-down(sm) {
            color: $black;
        }
    }
}