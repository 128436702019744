/* ==========================================================================
   Functions
   ========================================================================== */

@function pxToRem($size) {

    $remSize: $size / $font-size-base-px;
    @return #{$remSize}rem;
}

/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin clear-list() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin block($width: 100%, $height: 100%) {
	width: $width;
	height: $height;
	display: block;
}

@mixin inline-block($width: 100%, $height: 100%) {
	@include block($width, $height);
	display: inline-block;
}

@mixin pic ($width, $height) {
    overflow: hidden;
    @include  block($width, $height);
}

@mixin circle($size) {
    width: $size;
    height: $size;
    overflow: hidden;
    border-radius: $size;
}

@mixin absoluteTL($top: 0, $left: 0) {
    position: absolute;
    left: $left;
    top: $top;
}

@mixin absoluteTR($top: 0, $right: 0) {
    position: absolute;
    right: $right;
    top: $top;
}

@mixin absoluteBL($bottom: 0, $left: 0) {
    position: absolute;
    left: $left;
    bottom: $bottom;
}

@mixin absoluteBR($bottom: 0, $right: 0) {
    position: absolute;
    right: $right;
    bottom: $bottom;
}

@mixin opacity($opacity: 100) {
    opacity: ($opacity / 100);
    filter: alpha(opacity=$opacity); //IE8
}

@mixin gradient ($startColor, $endColor) {
    background-color: $startColor;
    background: linear-gradient(to bottom, $startColor 0%, $endColor 100%);
}

@mixin line ($background) {
    content: '';
    @include block(87px, 4px);
    background: $background;
}

@mixin background-dots ($color) {
    background-color: transparent;
    background-image: radial-gradient($color 19%, transparent 21%);
    background-size: pxToRem(62px) pxToRem(62px);
    opacity: .1;

    @include media-breakpoint-down(sm) {
        background-size: 30px 30px;
    }
}

@mixin without-pages() {

    @media (min-aspect-ratio: 2/1) {
        background: red;
    }
}

/*@mixin media-breakpoint-no-pages-down($name, $breakpoints: $grid-breakpoints) {

    $max: breakpoint-max($name, $breakpoints);

    @media (max-width: $max) and (min-aspect-ratio: 2/1) {
        @content;
    }
}// */

@mixin media-breakpoint-no-pages-up($name, $breakpoints: $grid-breakpoints) {

    $max: breakpoint-max($name, $breakpoints);

    @media
        (min-width: $max) and (min-aspect-ratio: 2/.9) and (max-width: 2200px),
        (min-width: 2200px) and (min-aspect-ratio: 3/1)
    {
        @content;
    }
}