@import "parts/mixins";
@import "bootstrap";
@import "sprite";
@import "parts/fonts";
@import "vendors";

@import "parts/icons";
@import "parts/links";
@import "parts/buttons";
@import "parts/helpers";
@import "parts/form";
@import "parts/js";
@import "parts/debug";

@import "parts/base";
@import "parts/loader";
@import "parts/float-header";
@import "parts/popup";
@import "parts/first";
@import "parts/logo";
@import "parts/sections";
@import "parts/wallet";
@import "parts/go-down";
@import "parts/price";
@import "parts/we-are";
@import "parts/products";
@import "parts/menu";
@import "parts/tasks";
@import "parts/big-logo";
@import "parts/list-with-icons";
@import "parts/problems-list";
@import "parts/gallery";
@import "parts/circle-button";
@import "parts/videos";
@import "parts/swiper-buttons";
@import "parts/instagram";
@import "parts/params";
@import "parts/about";
@import "parts/what-you-get";
@import "parts/doc";
@import "parts/features";
@import "parts/small-logo";
@import "parts/iphone-formula";
@import "parts/map";
@import "parts/terms";
@import "parts/forecast";
@import "parts/terms-cities";
@import "parts/gift";
@import "parts/business";
@import "parts/reasons-list";
@import "parts/circles-swiper";
@import "parts/advantages";
@import "parts/how-we-work";
@import "parts/notification";