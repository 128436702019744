.forecast {
    @extend .list-unstyled;
    display: flex;
    box-shadow: 0 pxToRem(3px) pxToRem(30px) 0 rgba($black, 0.08);
    border-radius: pxToRem(53px);
    background: $white;

    @include media-breakpoint-down(sm) {
        display: block;
    }

    li {
        padding: pxToRem(28px) pxToRem(42px) pxToRem(22px);
        font: $font-weight-medium pxToRem(13px) $font-family-base;
        border-right: 1px solid rgba($gray-700, .21);

        @include media-breakpoint-down(sm) {
            border-right: 0 none;
            border-top: 1px solid rgba($gray-700, .21);
        }

        &:first-child {
            padding-left: pxToRem(32px);

            @include media-breakpoint-down(sm) {
                border-top: 0 none;
                padding-left: pxToRem(42px);
            }
        }

        &:last-child {
            padding-right: pxToRem(32px);
            border-right: 0 none;
        }

        .amount {
            margin: pxToRem(5px) 0 0;
            font-size: pxToRem(27px);
        }

        .rub {
            vertical-align: pxToRem(3px);
        }
    }
}