.features {
    position: relative;
    padding-top: 17.2vh;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(200px) 0 pxToRem(200px);
    }

    &:before {
        @include absoluteTL(0, 34%);
        @include block(auto, auto);
        bottom: 0;
        right: 34%;
        content: '';
        z-index: 20;
        @include background-dots($yellow);
    }

    &__container,
    &__row {
        height: 100%;
    }

    &__about,
    &__apps {

        > *:not(.text-selected-yellow) {
            margin-bottom: pxToRem(20px);

            @include media-breakpoint-down(xl) {
                margin-bottom: pxToRem(15px);
            }
        }
    }

    &__about {

        @include media-breakpoint-down(sm) {
            padding-right: 0;
            padding-bottom: 40px;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    &__apps {
        padding-left: pxToRem(55px);

        @include media-breakpoint-down(lg) {
            padding-left: 0;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 40px;
            padding-right: 0;
            max-width: 400px;
            margin: 0 auto;
        }
    }

    &__center {
        display: flex;
        justify-content: center;
        position: relative;

        @include media-breakpoint-down(xl) {
            padding: 0 50px;
            margin: 0 auto;
        }
    }

    &__app-store > img {
        height: auto;

        @include media-breakpoint-down(md) {
            width: 100px;
        }

        @include media-breakpoint-down(sm) {
            width: auto;
        }
    }
}