.go-down {
    @include absoluteBL($grid-gutter-width, $grid-gutter-width);
    position: fixed;
    z-index: 200;

    @include media-breakpoint-down(lg) {
        display: none !important;
    }

    @include media-breakpoint-no-pages-up(sm) {
        display: none !important;
    }
}