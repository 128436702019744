.about {
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: 50px 0 0;
    }

    &:before {
        @include absoluteTR(27%, 0);
        @include block(62%, auto);
        bottom: 4%;
        content: '';
        z-index: 5;
        @include background-dots($yellow);

        @include media-breakpoint-down(sm) {
            z-index: -1;
        }
    }

    .section-left,
    .section-right {
        padding-top: 20vh;

        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }

        @include media-breakpoint-no-pages-up(sm) {
            padding: pxToRem(200px) 0 pxToRem(200px);
        }
    }

    .section-right {

        @include media-breakpoint-down(sm) {
            padding: 60px 25px;
        }
    }

    &__left-back {
        position: relative;

        &:before {
            @include block(50%, 100%);
            @include absoluteTR(0, 0);
            content: '';
            z-index: 0;
            background: url('../images/vegetables.jpg') 100% 100% no-repeat;

            @include media-breakpoint-down(xl) {
                background-size: cover;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    &__left-container {
        position: relative;
        z-index: 10;
    }

    &__about {
        padding-right: 42%;

        @include media-breakpoint-down(md) {
            padding-right: 36%;
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
            max-width: 400px;
            margin: 0 auto;
        }

        > * {
            margin-bottom: 4.4vh;
        }
    }

    &__wallet {

        .slider-line {
            margin: pxToRem(79px) 0 0 0;

            @include media-breakpoint-down(lg) {
                margin-top: 50px;
            }
        }
    }

    &__special {

        @include media-breakpoint-down(sm) {
            max-width: 370px;
        }
    }
}