/* ==========================================================================
   JS
   ========================================================================== */
.js-form {
    position: relative;

    .js-loader,
    .js-success,
    .js-error {
        display: none;
    }
}
