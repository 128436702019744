.instagram {
    position: relative;
    padding: 25vh 0 0 0;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(200px) 0 pxToRem(200px);
    }

    &:before {
        @include absoluteTR(22%, 0);
        @include block(64%, auto);
        bottom: 13%;
        content: '';
        z-index: 0;
        @include background-dots($purple);

        @include media-breakpoint-down(xl) {
            bottom: 11%;
        }
    }

    &__margin-before {
        margin-bottom: 6vh;

        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }
    }

    &__margin-after {
        margin-top: 6vh;

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
    }

    &__iphone {
        @include absoluteTL(50%, 50%);
        z-index: 200;
        margin: pxToRem(-304px) 0 0 pxToRem(-126px);

        @include media-breakpoint-down(xl) {
            transform-origin: center center;
            transform: scale(.7);
        }

        @include media-breakpoint-down(md) {
            transform: scale(.7) translateY(30px);
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }

        > img {
            width: 100%;
            height: auto;
        }
    }
}

.instagram-swiper-container {
    position: relative;
}

.instagram-swiper {
    width: 100%;

    .swiper-slide {
        height: pxToRem(332px);
        width: pxToRem(332px);

        @include media-breakpoint-down(xl) {
            height: pxToRem(250px);
            width: pxToRem(250px);
        }

        @include media-breakpoint-down(sm) {
            height: pxToRem(300px);
            width: pxToRem(300px);
        }

        img {
            @include block(100%, 100%);
            object-fit: cover;
        }
    }
}

.instagram-slide {
    @include block(100%, 100%);
}