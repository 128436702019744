
/* ==========================================================================
   Helpers
   ========================================================================== */
.debug {
    outline: 1px dotted #0c0;
}

.light      { font-weight: 300; }
.bold       { font-weight: $font-weight-bold; }
//.extra-bold { font-weight: $font-weight-extrabold; }

.height5  { height: 5px; }
.height10 { height: 10px; }
.height20 { height: 20px; }
.height25 { height: 25px; }
.height30 { height: 30px; }
.height40 { height: 40px; }
.height50 { height: 50px; }
.height60 { height: 60px; }
.height70 { height: 70px; }
.height80 { height: 80px; }
.height90 { height: 90px; }
.height100 { height: 100px; }

.no-padding {
    padding: 0 !important;

    &-left   { padding-left: 0 !important; }
    &-right  { padding-right: 0 !important; }
}

.pos-rel { position: relative; }

@each $color, $value in $colors {
    @include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $colors {
    @include text-emphasis-variant(".text-#{$color}", $value);
}

.amount {
    color: $gray-800;
    display: inline-block;
    font-size: pxToRem(23px);
    margin: 0 pxToRem(4px) 0 pxToRem(20px);

    @include media-breakpoint-down(md) {
        margin: 0;
    }

    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}

.rub,
.percent {
    display: inline-block;
    height: pxToRem(20px);
    font: $font-weight-bold pxToRem(21px) $font-family-base;
    color: $gray-800;
    background: $yellow;
    border-radius: pxToRem(10px);
    text-align: center;
    vertical-align: top;
    line-height: 1.05;

    .smaller {
        font-weight: $font-weight-medium;
        vertical-align: pxToRem(2px);
    }
}

.rub {
    width: auto;
    padding: 0 pxToRem(5px);
}

.percent {
    width: pxToRem(20px);
    padding: 0 pxToRem(1px);
    font-size: pxToRem(15px);
    line-height: 1.5;

    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

@each $color, $value in $colors {

    .text-selected-#{$color} {
        background: $value;
        display: inline-block;
        padding: pxToRem(5px) pxToRem(14px) pxToRem(4px);
        letter-spacing: -.6px;
    }
}

.smaller {
    font-size: 70%;
}