$circle-swiper-size: 573px;

$circle-swiper-size-coeffs: (
    xl: .8,
    lg: .7,
    md: .7,
    sm: .7,
    xs: .7,
    xxs: .5,
);

.circles-swiper {

    .swiper-slide {
        width: pxToRem(567px);
        height: pxToRem(567px);
        transition: all 500ms;
        transform: scale(0.6);
        transform-origin: left center;
        will-change: transform;

        @each $breakpoint, $coeff in $circle-swiper-size-coeffs {

            @include media-breakpoint-down($breakpoint) {
                width: pxToRem(567px * $coeff);
                height: pxToRem(567px * $coeff);
            }
        }
    }

    .swiper-slide-active {
        transform: scale(1);
        transform-origin: center center;
    }

    .swiper-slide-next {
        transform: scale(0.75);
        transform-origin: center center;

        @include media-breakpoint-down(sm) {
            transform: scale(0.85);
        }
    }
}

.circles-swiper-slide {
    width: 100%;
    padding-top: 100%;
    position: relative;

    > img {
        @include absoluteTL(0, 0);
        @include block(100%, 100%);
        border-radius: 100%;
        object-fit: cover;
    }
}

.circles-swiper-buttons {
    font: $font-weight-medium pxToRem(23px) $font-family-base;
    color: $gray-800;
    display: flex;
    align-items: center;

    &__pager {
        display: inline-block;
        padding: 0 pxToRem(25px);
        width: pxToRem(100px);
        text-align: center;
    }
}

.circle-swiper-about,
.circle-swiper-about-mini {
    min-height: 550px;

    @include media-breakpoint-up(mxl) {
        min-height: 700px;
    }

    @include media-breakpoint-down(xl) {
        min-height: 480px;
    }

    @include media-breakpoint-down(lg) {
        min-height: 420px;
    }

    @include media-breakpoint-down(md) {
        min-height: 340px;
    }

    @include media-breakpoint-down(sm) {
        min-height: 300px;
    }

    @include media-breakpoint-down(xs) {
        min-height: 380px;
    }
}

.circle-swiper-about-mini {
    min-height: 400px;

    @include media-breakpoint-up(mxl) {
        min-height: 500px;
    }

    @include media-breakpoint-down(xl) {
        min-height: 360px;
    }

    @include media-breakpoint-down(lg) {
        min-height: 300px;
    }

    @include media-breakpoint-down(md) {
        min-height: 260px;
    }

    @include media-breakpoint-down(sm) {
        min-height: 240px;
    }

    @include media-breakpoint-down(xs) {
        min-height: 280px;
    }
}