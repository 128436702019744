.before-input {
    font: $font-weight-medium pxToRem(16px) $font-family-base;
    color: rgba($black, .34);
    padding: pxToRem(47px) 0 pxToRem(39px);

    @include media-breakpoint-down(sm) {
        padding: 0 0 20px;
    }
}

.form-header {
    position: relative;
    font: $font-weight-extra-bold pxToRem(22px)/1.2272 $font-family-base;
    padding-left: pxToRem(105px);
    min-height: pxToRem(73px);
    color: $gray-800;
    display: flex;
    align-items: center;
    letter-spacing: .8px;
    margin-bottom: pxToRem(27px);

    @include media-breakpoint-down(sm) {
        margin-bottom: 34px;
        padding-left: 90px;
        font-size: pxToRem(18px);
    }

    &__icon {
        @include absoluteTL(0, 0);

        @include media-breakpoint-down(sm) {
            left: 13px;
        }

        &:before {
            @include circle(pxToRem(73px));
            @include absoluteTL(0, 0);
            content: '';
            display: block;
            background: $gray-100;
            z-index: 0;
        }

        *[class^="icon-"] {
            position: relative;
            z-index: 4;
        }
    }
}

.select2-container--default .select2-selection--single,
.select2-container .select2-selection--single,
.select2-container--default .select2-selection--multiple,
.select2-container .select2-selection--multiple,
.form-control {
    @extend .form-control;
    height: pxToRem(48px);
    padding: 0;
    font: $font-weight-base pxToRem(28px) $font-family-base;
    border: 0 none;
    border-bottom: 1px solid rgba($black, .3);

    &:focus,
    &:hover,
    &:active {
        outline: 0 none;
        border: 0 none;
        border-bottom: 1px solid rgba($black, .3);
        box-shadow: unset;
    }

    &:focus,
    &:active {
        border-bottom: 1px solid $yellow;
    }

    &::placeholder {
        font-size: pxToRem(16px);
    }

    &.error,
    &:invalid {

        &,
        &:focus,
        &:active {
            border: 0 none;
            border-bottom: 1px solid rgba($red, .5);
            outline: 0 none;
            box-shadow: unset;
        }
        /*
        .select2-container--default .select2-selection--single,
        .select2-container .select2-selection--single,
        .select2-container--default .select2-selection--multiple,
        .select2-container .select2-selection--multiple {
            border-bottom: 1px solid rgba($red, .5);
        }*/
    }

    &:valid {
        background: $white;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        background: $white;
    }
}
/*
textarea {

    &, &.form-control {
        height: 55px;
        resize: vertical;
        line-height: 1.3;
        padding-top: 5px;
        padding-bottom: 5px;

        &.big {
            height: 170px;
        }
    }
}
// */

.form-loader {
    @include block(100%, 100%);
    @include absoluteTL(0, 0);
    background: $white;
    text-align: center;
    z-index: 100;
    opacity: .6;

    > div {
        @include block(100%, 100%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
/*
.form-success,
.form-error {
    text-align: center;
    padding: 20px 0 20px;
    font: 600 22px $base-font-family;
    line-height: $base-line-height;
    color: lighten($color-black, 40%);
}
*/
label.error {
    position: absolute;
    left: 0;
    background: rgba($black, .8);
    border-radius: pxToRem(3px);
    font: 300 pxToRem(18px) $font-family-base;
    color: $white;
    margin: pxToRem(10px) 0 0;
    padding: pxToRem(6px) pxToRem(7px) pxToRem(5px);
    width: auto;
    white-space: nowrap;
    z-index: 100;
    display: none !important;

    @include media-breakpoint-down(md) {
        font-size: pxToRem(16px);
    }

    &:before {
        @include absoluteTL(pxToRem(-6px), pxToRem(15px));
        @include block(0, 0);
        content: '';
        border-style: solid;
        border-width: 0 pxToRem(7.5px) pxToRem(7px) pxToRem(7.5px);
        border-color: transparent transparent rgba($black, .8) transparent;
        z-index: 100;
    }
}

input.error:hover + label.error,
textarea.error:hover + label.error,
input.error:focus + label.error,
textarea.error:focus + label.error {
    display: inline-block !important;
}

.form-group {
    position: relative;

    @include media-breakpoint-down(sm) {
        margin-bottom: 34px;
    }

    label:not(.error) {
        @include absoluteTL(0, 0);
        height: pxToRem(50px);
        @include transition(1s);
        cursor: text;
        line-height: pxToRem(48px);
        transform: scale(.815) translateY(pxToRem(40px)) translateX(0);
        font-size: pxToRem(16px);
        transform-origin: left bottom;
        color: rgba($black, .34);
        font-weight: $font-weight-base;

        @include media-breakpoint-down(sm) {
            transform: scale(1) translateY(37px) translateX(0);
        }
    }

    .form-control {

        &::placeholder {
            opacity: 1;
            transition: all .2s;
        }

        &:placeholder-shown:not(:focus)::placeholder {
            opacity: 0;
        }

        &:placeholder-shown:not(:focus) + *:not(.error),
        &:placeholder-shown:not(:focus) + .error + * {
            transform: scale(1) translateY(0) translateX(0);
            font-weight: $font-weight-medium;
        }
    }
}

.form-result {
    border-top: 1px solid rgba($black, .28);
    padding: pxToRem(23px) 0 pxToRem(60px) 0;
    display: flex;
    justify-content: space-between;
    font: $font-weight-medium pxToRem(16px) $font-family-base;
    color: $gray-800;
    margin: pxToRem(59px) 0 0;

    @include media-breakpoint-down(lg) {
        margin-top: pxToRem(40px);
        padding: pxToRem(15px) 0 pxToRem(40px) 0;
    }

    @include media-breakpoint-down(sm) {
        font-size: pxToRem(14px);
    }
    
    > *:last-child {

        @include media-breakpoint-down(sm) {
            text-align: right;
        }
    }
}

.form-footer {
    display: flex;
    align-items: center;
    padding: pxToRem(7px) 0 0;

    @include media-breakpoint-down(sm) {
        display: block;
    }

    &__button {

        > * {
            display: block;
            margin: 0 auto;
        }
    }

    &__others {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            justify-content: center;
            padding-top: 20px;
        }
    }

    &__how-much {
        font: $font-weight-extra-bold pxToRem(45px) $font-family-base;
        padding: 0 pxToRem(19px) 0 pxToRem(24px);
    }

    &__requests {
        font: $font-weight-medium pxToRem(16px) $font-family-base;
        color: $black;
    }

    &__profit {
        font: $font-weight-medium pxToRem(16px) $font-family-base;
        padding: 0 0 0 pxToRem(24px);

        @include media-breakpoint-down(md) {
            padding-left: pxToRem(12px);
        }
    }
}

.profit {
    line-height: 1.4;

    &__summ {
        font-size: pxToRem(30px);
    }

    .rub {
        vertical-align: pxToRem(2px);
    }
}

// Select2 customization
.select2-container--default .select2-selection--single,
.select2-container .select2-selection--single,
.select2-container--default .select2-selection--multiple,
.select2-container .select2-selection--multiple {
    border-radius: 0;
    padding: pxToRem(11px) 0;

    .select2-selection__rendered {
        padding-left: 0;
    }

    .select2-selection__arrow {
        @include circle(pxToRem(27px));
        background: $gray-300;
        top: pxToRem(10px);
    }
}

// select 2 customization
.select2-container {

    .select2-search {
        display: none;
    }
}

.select2-dropdown {
    box-shadow: 0 10px 20px 0 rgba($black, .05);
    border-radius: 0;
    border: 0 none;
}