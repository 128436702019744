$circle-size-big-logo: 573px;

$circle-size-big-logo-coeffs: (
    xl: .87,
    lg: .8,
    md: .73,
    sm: .62,
);

.big-logo {
    @include circle(pxToRem($circle-size-big-logo));
    display: flex;
    align-items: center;
    justify-content: center;

    @each $breakpoint, $coeff in $circle-size-big-logo-coeffs {

        @include media-breakpoint-down($breakpoint) {
            @include circle(pxToRem($circle-size-big-logo * $coeff));
        }
    }

    &--pink {
        background: $pink;
    }

    &__inner {
        color: $white;
        text-align: center;

    }

    &__image {

        svg {
            width: pxToRem(361px);
            height: pxToRem(153px);

            @each $breakpoint, $coeff in $circle-size-big-logo-coeffs {

                @include media-breakpoint-down($breakpoint) {
                    width: pxToRem(361px * $coeff);
                    height: pxToRem(153px * $coeff);
                }
            }
        }
    }

    &__text {
        font: $font-weight-extra-bold pxToRem(21px) $font-family-base;
        letter-spacing: .6px;
        padding: pxToRem(15px) 0 0;

        @each $breakpoint, $coeff in $circle-size-big-logo-coeffs {

            @include media-breakpoint-down($breakpoint) {
                font-size: pxToRem(21px * $coeff);
                padding-top: pxToRem(15px * $coeff);
            }
        }
    }
}