.params {
    @extend .list-unstyled;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
        max-width: 400px;
    }

    li {
        color: $black;
        line-height: 1.29;
        font-weight: $font-weight-medium;

        .num {
            font: $font-weight-extra-bold pxToRem(34px) $font-family-base;
            display: inline-block;
            color: $black;
            padding: pxToRem(11px) pxToRem(17px);
            margin-bottom: pxToRem(8px);
            white-space: nowrap;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                padding: 5px 8px 4px;
            }
        }
    }

    li + li {
        margin-left: pxToRem(40px);

        @include media-breakpoint-down(sm) {
            margin-left: 10px;
        }
    }
}