/* ==========================================================================
   Icons
   ========================================================================== */

.icon-pdf-doc {
    display: inline-block;
    padding: pxToRem(8px) 0 0 pxToRem(10px);
    position: relative;

    &:after {
        content: 'PDF';
        @include absoluteTL(pxToRem(23px), pxToRem(19px));
        text-transform: uppercase;
        font: $font-weight-bold pxToRem(10px) $font-family-base;
        color: $gray-600;
        z-index: 2;
    }

    svg {
        position: relative;
        width: pxToRem(57px);
        height: pxToRem(57px);
        z-index: 1;
    }
}

.icon-timer,
.icon-scale,
.icon-heart,
.icon-dish,
.icon-question,
.icon-meal,
.icon-rouble,
.icon-truck,
.icon-crowd,
.icon-body,
.icon-smile {
    @include inline-block(pxToRem(73px));
    display: inline-flex;
    align-items: center;
    position: relative;

    svg {
        margin: 0 auto;
        display: block;
    }
}

.icon-timer {

    &:before,
    &:after {
        text-transform: uppercase;
        font: $font-weight-extra-bold pxToRem(11px) $font-family-base;
        color: $gray-600;
        z-index: 2;
        position: absolute;
        top: pxToRem(32px);
    }

    &:before {
        content: '03';
        left: pxToRem(21px);
    }

    &:after {
        content: '00';
        left: pxToRem(40px);
    }

    svg {
        position: relative;
        width: pxToRem(57.4px);
        height: pxToRem(67px);
        z-index: 1;
        margin-top: pxToRem(-10px);
    }
}

.icon-scale {

    svg {
        width: pxToRem(62px);
        height: pxToRem(62px);
    }
}

.icon-heart {

    svg {
        width: pxToRem(70px);
        height: pxToRem(59px);
    }
}

.icon-dish {

    svg {
        width: pxToRem(66px);
        height: pxToRem(66px);
    }
}

.icon-question {

    svg {
        width: pxToRem(58px);
        height: pxToRem(63px);
        margin-top: pxToRem(6px);
    }
}

.icon-meal {

    svg {
        width: pxToRem(49px);
        height: pxToRem(49px);
    }
}

.icon-rouble {

    svg {
        width: pxToRem(51px);
        height: pxToRem(51px);
    }
}

.icon-truck {

    svg {
        width: pxToRem(50px);
        height: pxToRem(50px);
    }
}

.icon-crowd {

    svg {
        width: pxToRem(52px);
        height: pxToRem(52px);
    }
}

.icon-body {

    svg {
        width: pxToRem(58px);
        height: pxToRem(56px);
    }
}

.icon-smile {

    svg {
        width: pxToRem(56px);
        height: pxToRem(56px);
    }
}

.icon-arrow-down,
.icon-arrow-left,
.icon-arrow-right,
.icon-close {
    display: inline-block;
    line-height: 0;

    svg {
        stroke: $gray-600;
        fill: $gray-600;
    }
}

.icon-arrow-down {
    height: pxToRem(20.5px);
    width: pxToRem(14.5px);

    svg {
        height: pxToRem(20.5px);
        width: pxToRem(14.5px);
    }
}

.icon-arrow-left,
.icon-arrow-right {
    width: pxToRem(20.5px);
    height: pxToRem(14.5px);

    svg {
        width: pxToRem(20.5px);
        height: pxToRem(14.5px);
    }
}

.icon-play {
    transition: all 500ms;

    svg {
        transition: all 500ms;
        fill: $white;
    }

    &:hover {
        transform: scale(1.1);

        svg {
            fill: $dark-red;
        }
    }
}

.icon-close {

    svg {
        width: pxToRem(14px);
        height: pxToRem(14px);
    }
}

.icon-wallet {
    padding: 0 0 0 pxToRem(10px);

    svg {
        width: pxToRem(51px);
        height: pxToRem(74px);
        margin-top: pxToRem(-6px);
    }
}

.icon-pin-red,
.icon-pin-green {

    svg {
        width: pxToRem(15px);
        height: pxToRem(20px);
    }
}

.icon-pin-green svg {
    fill: $teal;
}

.icon-pin-red svg {
    fill: $red;
}

.icon-gift {
    display: inline-block;
    padding: pxToRem(6px) 0 0 pxToRem(6px);

    svg {
        width: pxToRem(61px);
        height: pxToRem(61px);
    }
}

.icon-letter {
    display: inline-block;
    position: relative;
    padding: pxToRem(3px) 0 0 pxToRem(4px);

    &:after {
        content: 'PDF';
        @include absoluteTL(pxToRem(7px), pxToRem(11px));
        text-transform: uppercase;
        font: $font-weight-bold pxToRem(13px) $font-family-base;
        color: $gray-600;
        z-index: 2;
    }

    svg {
        width: pxToRem(41px);
        height: pxToRem(63px);
    }
}

.icon-call {

    svg {
        width: 27.5px;
        height: 27.5px;
        fill: $white;
        stroke: $white;
    }
}