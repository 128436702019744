.small-logo {
    @include block(pxToRem(150px), pxToRem(150px));
    border-radius: pxToRem(28px);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    @include media-breakpoint-down(xl) {
        @include block(pxToRem(120px), pxToRem(120px));
        border-radius: pxToRem(22px);
        display: flex;
    }

    @include media-breakpoint-down(sm) {
        @include block(pxToRem(100px), pxToRem(100px));
        border-radius: pxToRem(19px);
        display: flex;
    }

    &__image {
        display: inline-block;
        line-height: 0;

        &, & svg {
            width: pxToRem(81px);
            height: pxToRem(121px);

            @include media-breakpoint-down(xl) {
                width: pxToRem(81px / 1.25);
                height: pxToRem(121px / 1.25);
            }

            @include media-breakpoint-down(sm) {
                width: pxToRem(54px);
                height: pxToRem(80px);
            }
        }

        svg {
            fill: $white;
        }
    }
}