.terms-cities {
    display: flex;

    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.terms-city {
    background: $white;
    border-radius: pxToRem(40px);
    min-height: 50.47vh;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    box-shadow: 0 pxToRem(3px) pxToRem(10px) 0 rgba($black, .07);
    display: flex;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        height: 300px;
        min-height: unset;
    }

    > * {
        flex-grow: 1;
    }

    &__image {
        width: 62.7%;
    }

    &__about {
        width: 37.3%;
        display: flex;
        flex-direction: column;
    }

    & + & {
        margin-left: pxToRem(20px);

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}

.terms-city-param {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font: $font-weight-medium pxToRem(15px)/1.2 $font-family-base;
    color: rgba($black, .34);
    padding: 0 0 pxToRem(4px);

    & + & {
        border-top: 1px solid rgba(#707070, .21);
    }

    .first-line {

    }

    .second-line {
        font: $font-weight-medium pxToRem(22px) $font-family-base;
        color: $gray-800;
        padding-top: pxToRem(8px);
    }

    &--first {

        .first-line {
            font:$font-weight-medium pxToRem(13px) $font-family-base;
            color: $gray-800;
        }

        .second-line {
            font:$font-weight-medium pxToRem(29px)/1 $font-family-base;
            color: $gray-800;

            @include media-breakpoint-down(sm) {
                font-size: pxToRem(24px);
            }

            .rub {
                vertical-align: pxToRem(2px);
                font-size: pxToRem(24px);
                height: pxToRem(24px);
                width: pxToRem(24px);
                border-radius: pxToRem(12px);
                line-height: 1.1;
            }
        }
    }
}

.terms-city-image {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: pxToRem(41px) 0 pxToRem(36px);
    justify-items: center;

    &__image {
        @include block(100%, 100%);
        @include absoluteTL(0, 0);
        object-fit: cover;
        z-index: 5;
        border-radius: pxToRem(40px) pxToRem(40px) 0 pxToRem(40px);
    }

    &__cities,
    &__button {
        position: relative;
        z-index: 10;
        text-align: center;
    }

    &__cities {
        font:$font-weight-medium pxToRem(13px) $font-family-base;
        color: $gray-800;
    }

    &__count {
        font:$font-weight-medium pxToRem(24px)/1.7 $font-family-base;
        color: $gray-800;
    }

    &__button {
        margin-top: auto;
    }
}