// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$cold-name: 'cold';
$cold-x: 0px;
$cold-y: 0px;
$cold-offset-x: 0px;
$cold-offset-y: 0px;
$cold-width: 40px;
$cold-height: 40px;
$cold-total-width: 85px;
$cold-total-height: 80px;
$cold-image: '../images/sprite.png';
$cold: (0px, 0px, 0px, 0px, 40px, 40px, 85px, 80px, '../images/sprite.png', 'cold', );
$hot-name: 'hot';
$hot-x: 45px;
$hot-y: 0px;
$hot-offset-x: -45px;
$hot-offset-y: 0px;
$hot-width: 40px;
$hot-height: 40px;
$hot-total-width: 85px;
$hot-total-height: 80px;
$hot-image: '../images/sprite.png';
$hot: (45px, 0px, -45px, 0px, 40px, 40px, 85px, 80px, '../images/sprite.png', 'hot', );
$mail-name: 'mail';
$mail-x: 0px;
$mail-y: 45px;
$mail-offset-x: 0px;
$mail-offset-y: -45px;
$mail-width: 39px;
$mail-height: 35px;
$mail-total-width: 85px;
$mail-total-height: 80px;
$mail-image: '../images/sprite.png';
$mail: (0px, 45px, 0px, -45px, 39px, 35px, 85px, 80px, '../images/sprite.png', 'mail', );
$spritesheet-width: 85px;
$spritesheet-height: 80px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($cold, $hot, $mail, );
$spritesheet: (85px, 80px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
