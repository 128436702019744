$gift-size-coeffs: (
    xl: .87,
    lg: .8,
    md: .73,
);

.gift {
    position: relative;
    padding-top: 17.2vh;

    @include media-breakpoint-down(sm) {
        padding: 50px 0 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(200px) 0 pxToRem(150px);
    }

    &:before,
    &:after {
        position: absolute;
        top: 25%;
        display: block;
        bottom: 12%;
        content: '';
        z-index: 0;
        @include background-dots($dark-red);
    }

    &:before {
        left: 0;
        width: 3%;
    }

    &:after {
        right: 0;
        width: 65%;
    }

    .section-right {

        @include media-breakpoint-down(sm) {
            padding: 10px 25px 50px;
        }
    }

    &__wallet {}

    &__left-back {
        position: relative;
        z-index: 10;
    }

    &__about {
        max-width: 400px;

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }

        > *:not(.h5) {
            margin-bottom: pxToRem(36px);
        }
    }
}

.gift-wallet {
    position: relative;

    &__gift {
        @include absoluteTL(pxToRem(36px), pxToRem(-408px));
        z-index: 2;

        @each $breakpoint, $coeff in $gift-size-coeffs {

            @include media-breakpoint-down($breakpoint) {
                @include absoluteTL(pxToRem(36px * $coeff), pxToRem(-408px * $coeff));
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__image {
        @include block(478px, 478px);
        position: relative;
        z-index: 5;

        @each $breakpoint, $coeff in $gift-size-coeffs {

            @include media-breakpoint-down($breakpoint) {
                @include block(478px * $coeff, 478px * $coeff);
            }
        }
    }

    &__bill {
        @include block(477px, 208px);
        @include absoluteTL(pxToRem(143px), pxToRem(-173px));
        z-index: 0;
        transform-origin: bottom right;

        @each $breakpoint, $coeff in $gift-size-coeffs {

            @include media-breakpoint-down($breakpoint) {
                @include block(477px * $coeff, 208px * $coeff);
                @include absoluteTL(pxToRem(143px * $coeff), pxToRem(-173px * $coeff));
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &:nth-child(1n) { transform: rotate(59deg); }
        &:nth-child(2n) { transform: rotate(16deg); }
        &:nth-child(3n) { transform: rotate(30deg); }
        &:nth-child(4n) { transform: rotate(46deg); }
    }
}

.countdown {
    color: $dark-red;
    font: $font-weight-extra-bold pxToRem(66px) $font-family-base;
}

.gift-selected {
    background: $dark-red;
    color: $white;
    padding: pxToRem(7px) pxToRem(16px);
    margin: pxToRem(-10px) pxToRem(-7px) pxToRem(-5px) pxToRem(7px);
    display: inline-block;
}