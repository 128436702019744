.how-we-work {
    position: relative;
    padding-top: 17.2vh;

    @include media-breakpoint-down(sm) {
        padding: 50px 0;
    }

    @include media-breakpoint-no-pages-up(sm) {
        padding: pxToRem(100px) 0 pxToRem(110px);
    }

    &:before {
        @include absoluteTR(27%, 0);
        @include block(57.2%, auto);
        bottom: 6.5%;
        content: '';
        z-index: -1;
        @include background-dots($pink);
    }

    &__about {
        padding: 0 30px 0 0;

        @include media-breakpoint-down(sm) {
            padding-right: 0;
        }
    }

    &__buttons {
        padding: pxToRem(45px) 0 pxToRem(47px) pxToRem(11px);

        @include media-breakpoint-down(lg) {
            padding: pxToRem(20px) 0 pxToRem(30px) pxToRem(11px);
        }
    }

    &__text {

        > * {
            margin-bottom: pxToRem(19px);
        }
    }
}