.circle-button {
    @include circle(pxToRem(37px));
    background: $gray-300;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 pxToRem(8px) rgba($gray-100, 1);
    transition: all 500ms;

    &:hover {
        background: $gray-100;
    }

    &.swiper-button-disabled {
        cursor: not-allowed;
        opacity: .4;
    }
}

.circle-link {
    display: inline-flex;
    align-content: center;
    align-items: center;
    font-weight: $font-weight-medium;
    color: $gray-600;

    .circle-button {
        margin-right: pxToRem(22px);
    }
}