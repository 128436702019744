:root {
    font-size: $font-size-base-px;

    @include media-breakpoint-down(xl) {
        font-size: 15px;
    }

    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }

    @include media-breakpoint-down(md) {
        font-size: 12px;
    }

    @include media-breakpoint-down(xxs) {
        font-size: 13px;
    }
}

.fp-enabled body {
    overflow: visible;
}

* {
    /*image-rendering: -moz-crisp-edges;         !* Firefox *!
    image-rendering:   -o-crisp-edges;         !* Opera *!
    image-rendering: -webkit-optimize-contrast;!* Webkit (non-standard naming) *!
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  !* IE (non-standard property) *!*/
}