.logo {
    display: flex;
    cursor: pointer;
    padding: pxToRem(12px) pxToRem(28px) 0 pxToRem(28px);

    @include media-breakpoint-down(sm) {
        padding-left: pxToRem(17.5px);
        padding-right: pxToRem(17.5px);
    }

    &__image {
        width: pxToRem(180px);
        height: pxToRem(77px);
        position: relative;
        margin-top: 0;

        svg {
            fill: $white;
        }

        &, & svg {
            width: pxToRem(180px);
            height: pxToRem(77px);

            @include media-breakpoint-down(sm) {
                width: 112px;
                height: 48px;
            }
        }

        &:after {
            @include circle(pxToRem(239px));
            content: '';
            background: $yellow;
            @include absoluteTL(pxToRem(-80px), pxToRem(-29px));
            z-index: -1;

            @include media-breakpoint-down(sm) {
                @include circle(190px);
                @include absoluteTL(-104px, -40px);
            }
        }
    }

    &__text {
        color: $gray-600;
        font-size: pxToRem(19px);
        font-weight: $font-weight-medium;
        line-height: 1.25;
        display: block;
        padding: pxToRem(5px) 0 0 pxToRem(68px);

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .float-header--mini & {
        padding: pxToRem(17px) 0 0 pxToRem(18px);

        @include media-breakpoint-down(sm) {
            margin-top: -26px;
            padding-left: 0;
            padding-right: 0;
        }

        .logo__image {
            margin-top: pxToRem(8px);
            //margin-left: 4px;

            &, & svg {
                width: pxToRem(116px);
                height: pxToRem(49px);
            }

            &:after {
                @include circle(pxToRem(154px));
                @include absoluteTL(pxToRem(-52px), pxToRem(-19px));

                @include media-breakpoint-down(sm) {
                    top: pxToRem(-70px);
                }
            }
        }

        .logo__text {
            padding: 0 0 0 pxToRem(51px);
        }
    }
}