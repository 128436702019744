.list-with-icons {
    @extend .list-unstyled;

    @include media-breakpoint-down(xs) {
        margin: 0 auto;
        max-width: 240px;
        text-align: left;
    }

    li {
        position: relative;
        padding-left: pxToRem(106px);
        min-height: pxToRem(73px);
        display: flex;
        align-items: center;
        font: $font-weight-medium pxToRem(17px)/1.29 $font-family-base;
        color: $gray-600;

        @include media-breakpoint-down(md) {
            padding-left: pxToRem(90px);
        }

        @include media-breakpoint-only(sm) {
            width: 50%;
            float: left;
            padding-top: 30px;
            padding-bottom: 30px;
            padding-right: 20px;
            text-align: left;
        }

        .icon {
            @include circle(pxToRem(73px));
            background: $gray-100;
            @include absoluteTL(0, 0);
            overflow: visible;

            @include media-breakpoint-only(sm) {
                top: 20px;
            }
        }
    }

    li + li {
        margin-top: 5vh;

        @include media-breakpoint-down(lg) {
            margin-top: 4vh;
        }

        @include media-breakpoint-down(md) {
            margin-top: 3vh;
        }

        @include media-breakpoint-only(sm) {
            margin-top: 0;
        }
    }
}