.reasons-list {}

.reason-card {
    color: $gray-800;
    padding: 0 pxToRem(30px) 0 0;

    @include media-breakpoint-down(lg) {
        font-size: 0.9rem
    }

    @include media-breakpoint-down(lg) {
        padding-right: 0;
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 30px;
    }

    &__header {
        display: flex;
        align-items: center;
        padding: 0 pxToRem(30px) pxToRem(22px) 0;
    }

    &__icon {
        @include circle(pxToRem(73px));
        background: $gray-100;
        display: block;
        overflow: visible;
    }

    &__title {
        font: $font-weight-medium pxToRem(17px)/1.29 $font-family-base;
        color: $gray-600;
        margin-left: pxToRem(24px);

        @include media-breakpoint-down(lg) {
            font-size: 0.9rem
        }
    }
}